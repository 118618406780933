/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

@import 'styles/helpers';
@import 'styles/typography';
@import 'styles/theme';
@import 'styles/tooltip';
@import 'styles/badge';
@import 'styles/label';
@import 'styles/colors';
@import 'styles/buttons';
@import 'styles/progress';
@import 'styles/note';
@import 'styles/menu';
@import 'styles/pagination';
// @import 'styles/stepper';
@import 'styles/table';
@import 'styles/tabs';
@import 'styles/forms';
@import 'styles/modal';

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

@include breakpoint-min($tablet) {
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: var(--scrollbar-color, var(--color-neutral-90));
    border: 6px solid var(--scrollbar-background, var(--color-neutral-10));
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-color, var(--color-neutral-90));
  }
}

html,
body {
  height: 100%;

  @include breakpoint-min($tablet) {
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
  }
}

body {
  background-color: var(--color-neutral-10);
}

.mat-drawer-container {
  background-color: transparent;
}

.mat-icon {
  svg {
    width: 1em;
    height: 1em;
  }
}

.page-content {
  padding: 24px;
  margin: 0 auto;
  border-radius: 4px;
  background: var(--color-light);
  box-shadow: inset 0 0 0 1px var(--color-neutral-30);

  @include breakpoint-max($laptop-small) {
    padding: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

a {
  text-decoration: underline;
  color: var(--color-primary-60);

  &:hover {
    text-decoration: none;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--color-primary-60);
}

.form-field-flex {
  display: flex;
}

.alert {
  display: inline-block;
  direction: ltr;
  font-style: italic;
  line-height: 1.4;
  border-left: 2px solid var(--color-primary-60);
  background-color: #fff3d7;
  margin-bottom: 16px;
  padding: 8px 16px;

  &.error {
    float: left;
    clear: left;
    border-color: #b00f23;
    background-color: #fac4cb;
  }

  .notice {
    margin: 0;
  }
}

.mat-drawer-container {
  .mat-drawer-content {
    overflow: hidden;
    position: static;
    margin-top: 72px;
    height: calc(100% - 72px);
    margin-inline-start: 220px !important;

    html:not(.rtl) & {
      margin-right: 0 !important;
    }

    html.rtl & {
      margin-left: 0 !important;
    }

    @include breakpoint-max($laptop-small) {
      margin-top: 64px;
      height: calc(100% - 64px);
      margin-inline-start: 200px !important;
    }

    @include breakpoint-max($tablet) {
      height: 100%;
      margin-top: 0;
      overflow-y: auto;
      padding-top: 80px;
      overflow-x: hidden;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      margin-inline-start: 0 !important;
    }
  }
}

.mat-menu-panel {
  box-shadow: inset 0 0 0 1px var(--color-neutral-20);
}

.material-icons,
.cdk-overlay-connected-position-bounding-box {
  direction: inherit;
}

.language-list {
  margin-top: 34px;

  .mat-select-panel-wrap .mat-select-panel {
    margin-top: 0;
  }
}

/* .intercom-namespace .intercom-dfosxs, */
/* .intercom-lightweight-app-launcher { */
/*   @include breakpoint-max($tablet) { */
/*     bottom: 100px !important; */
/*   } */
/* } */